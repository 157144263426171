<template>
  <div class="footer-container row">
    <div class="col col-12">
      <ul class="footer-list">
        <li class="footer-item">
          津ICP备20003153号
        </li>
        <li class="footer-item">
          津ICP备20003153号-2
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//import ''
export default {
  data() {
    return {}
  },
  created() {

  },
  mounted() {

  },
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style lang="less" scoped>
//@import ''
.footer-container{
  .footer-list{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .footer-item{
      margin-right: 20px;
      color: #1366d7;
      font-weight: bold;
    }
  }
}
</style>
