<template>
  <div class="view-container">
    <div class="content clear">
      <div class="teaser-container spacing-xl shadow-t">
        <img src="../assets/product-teaser.png" alt="">
        <div class="text-content-t-l">
          <div class="font-size-xl spacing-xs color-w">丰富多样的租赁产品</div>
          <div class="font-size-m color-w">您可以根据自己的需求选择相应的租赁产品，祝您畅享BMW之悦。</div>
        </div>
      </div>
      <div class="row">
        <div class="col col-12 font-size-xl spacing-m">丰富的租赁产品</div>
      </div>
      <div class="row">
        <div class="col col-12 shadow-l">
          <img src="../assets/product-list-1.png" alt="">
          <div class="text-content-b-l">
            <div class="font-size-m spacing-xs color-w">宝马短租服务</div>
            <div class="font-size-s spacing-xs color-w">为尊贵的宝马集团客户提供方便快捷的租车服务。</div>
            <div class="font-size-xs color-w go-service" @click="goService">
              <img src="../assets/icon_next.png" alt="">
              了解更多
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-6 shadow-b">
          <img src="../assets/product-list-2.png" alt="">
          <div class="text-content-b-c">
            <div class="font-size-m color-w">5年超长期限，轻松购车。</div>
            <div class="font-size-m spacing-xs color-w">每月固定月付款，简单便捷。</div>
            <div class="font-size-xs spacing-xs color-w">悦贷悦享融资租赁方案</div>
            <div class="font-size-xs color-w">报价咨询请联系先锋租赁服务热线 <span class="bold color-blue">400-995-6618</span> </div>
          </div>
        </div>
        <div class="col col-6 shadow-b">
          <img src="../assets/product-list-3.png" alt="">
          <div class="text-content-b-c">
            <div class="font-size-m spacing-xs color-w">弹性尾款，期末轻松换新车。</div>
            <div class="font-size-xs spacing-xs color-w">弹性悦贷悦享融资租赁方案</div>
            <div class="font-size-xs color-w">报价咨询请联系先锋租赁服务热线 <span class="bold color-blue">400-995-6618</span> </div>
          </div>
        </div>
      </div>
      <div class="row spacing-l">
        <div class="col col-6 shadow-b">
          <img src="../assets/product-list-4.png" alt="">
          <div class="text-content-b-c">
            <div class="font-size-m color-w">长期限，低月付</div>
            <div class="font-size-m color-w">租赁期间使用先锋牌照</div>
            <div class="font-size-m spacing-xs color-w">租金全额开具增值税专用发票。</div>
            <div class="font-size-xs spacing-xxs color-w">BMW悦加计划（融资租赁方案）</div>
            <div class="font-size-xs spacing-xs color-w">当前在成都、青岛、重庆、郑州、厦门、天津、南京、杭州、深圳等城市推出。</div>
            <div class="font-size-xs color-w">报价咨询请联系先锋租赁服务热线 <span class="bold color-blue">400-995-6618</span> </div>
          </div>
        </div>
        <div class="col col-6 shadow-b">
          <img src="../assets/product-list-5.png" alt="">
          <div class="text-content-b-c">
            <div class="font-size-m color-w">灵活期末选择，可购买、</div>
            <div class="font-size-m spacing-xs color-w">申请返还或申请延期。</div>
            <div class="font-size-xs spacing-xs color-w">BMW悦选明天计划（融资租赁方案）</div>
            <div class="font-size-xs color-w">报价咨询请联系先锋租赁服务热线 <span class="bold color-blue">400-995-6618</span> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ''
export default {
  data() {
    return {}
  },
  created() {

  },
  mounted() {

  },
  computed: {},
  methods: {
    goService:function () {
      this.$router.push({
        path:'/service'
      })
    },
  },
  watch: {}
}
</script>

<style lang="less" scoped>
//@import ''
.go-product,.go-service,.go-about{
  cursor: pointer;
  img{
    display: inline-block;
    width: 20px;
    height: 16px;
    vertical-align: text-top;
  }
}
</style>
