import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home'
import productView from '@/views/product'
import serviceView from '@/views/service'
import aboutView from '@/views/about'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{name:'概述'}
  },
  {
    path: '/product',
    name: 'product',
    component: productView,
    meta:{name:'BMW租赁产品'}
  },
  {
    path: '/service',
    name: 'service',
    component: serviceView,
    meta:{name:'短租服务'}
  },
  {
    path: '/about',
    name: 'about',
    component: aboutView,
    meta:{name:'联系我们'}
  },
]

const router = new VueRouter({
  //mode: 'history',
  mode: 'hash',
  routes,
  //页面滚动.
  scrollBehavior(to, from, savedPosition) {
    return {y:0}
  },
})

//路由守卫
router.beforeEach((to, from, next) => {
  //document.title = to.meta.name
  next()
});

export default router
