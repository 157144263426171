<template>
  <div class="view-container">
    <div class="content clear">
      <div class="teaser-container shadow-t">
        <img src="../assets/service-teaser.png" alt="">
        <div class="text-content-t-l">
          <div class="font-size-xl spacing-xs color-w">宝马短租服务</div>
          <div class="font-size-m color-w">由宝马集团旗下先锋租赁提供的宝马短租服务致力于打造宝马客户服务体系闭环，</div>
          <div class="font-size-m color-w">为集团客户提供方便快捷的租车服务。</div>
        </div>
        <div class="table-container">
          <div class="table-text spacing-xs color-w">预报价表</div>
          <img class="table-img" src="../assets/table.png" alt="">
        </div>
      </div>
      <div class="content-container row">
<!--        <div class="col col-4 iframe-container">-->
<!--          <div class="font-size-l spacing-xxs">立即租车</div>-->
<!--          <iframe class="myAppIframe" :src="iframeAppSrc" frameborder="0"></iframe>-->
<!--        </div>-->
<!--        <div class="col col-1"></div>-->
<!--        <div class="col col-7">-->
<!--          <div class="col col-12 font-size-l spacing-l">微信支付效果展示</div>-->
<!--          <div class="col col-6"><img src="../assets/pay-detail-1.jpg" alt=""></div>-->
<!--          <div class="col col-6"><img src="../assets/pay-detail-2.jpg" alt=""></div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
//import ''
export default {
  data() {
    return {
      iframeAppSrc:'https://ms-int.bmw-leasing.com.cn/mobappTest/'
    }
  },
  created() {

  },
  mounted() {

  },
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style lang="less" scoped>
//@import ''
.content-container{
  display: flex;
  margin-bottom: 45px;
  .iframe-container{
    margin: 0 auto;
  }
}
.myAppIframe{
  width: 100%;
  min-width: 375px;
  height: calc(100% - 130px) ;
  min-height: 750px;
}
.table-container{
  position: absolute;
  left: 100px;
  bottom: 15%;
  width: 100%;
  z-index: 1;
  .table-text{
    font-size: 32px;
  }
  .table-img{
    display: block;
    width: 36%;
    background-color: rgba(0,0,0,.3);
    backdrop-filter: blur(10px);
  }
}
</style>
