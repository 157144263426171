<template>
  <div class="app-container">
    <nav class="navigator">
      <router-link to="/">概述</router-link>
      <router-link to="/product">BMW租赁产品</router-link>
      <router-link to="/service">短租服务</router-link>
      <router-link to="/about">联系我们</router-link>
    </nav>
    <keep-alive>
      <router-view/>
    </keep-alive>
    <myFooter></myFooter>
  </div>
</template>
<script>
import myFooter from '@/components/footer';
export default {
  data() {
    return {}
  },
  created() {
    //强制跳转服务
    this.$router.push({
      path:'/service'
    })
  },
  mounted() {

  },
  computed: {},
  methods: {},
  watch: {},
  components:{
    myFooter
  }
}
</script>
<style lang="less">
.app-container {
  .navigator{
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding-left: 100px;
    margin-bottom: 65px;
    &:after{
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: #E8E8E8;
      left: 0;
      bottom: 0;
      z-index: 0;
    }
    a {
      position: relative;
      text-align: center;
      box-sizing: border-box;
      display: inline-block;
      height: 65px;
      line-height: 65px;
      padding: 0 20px;
      font-size: 21px;
      font-weight: 600;
      color: #666666;
      z-index: 1;
      &.router-link-exact-active {
        color: #262626;
        border-bottom: 3px solid #4d4d4d;
      }
    }
  }
}


</style>
