<template>
  <div class="view-container">
    <div class="content clear">
      <div class="teaser-container spacing-l shadow-t">
        <img src="../assets/about-teaser.png" alt="">
        <div class="text-content-t-l">
          <div class="font-size-xl color-w">联系我们</div>
        </div>
      </div>
      <div class="row">
        <div class="col col-12 font-size-s spacing-l">先锋国际融资租赁有限公司（简称先锋租赁）成立于2009年，是一家专注于融资租赁和经营租赁业务的企业。2016年4月，先锋租赁正式加入宝马集团。 2018年3月，华晨宝马汽车有限公司成为先锋租赁的股东之一。作为宝马集团在中国的第五家法人实体公司，先锋租赁为个人和企业提供BMW、MINI、BMW摩托 车和劳斯莱斯等宝马集团旗下品牌专属的多种租赁产品和服务，并针对企业商务出行提供高端定制化解决方案。先锋租赁正以日益多元化的业务组合， 不断满足更多客户的出行需求。先锋租赁是“宝马租赁”及其相关品牌和商标的授权使用者。</div>
        <div class="col col-12 font-size-s spacing-s">宝马先锋租赁一直秉承着以客户为中心的服务宗旨，坚持着“从容、信任、关怀”的基本原则，为您提供更好的产品和服务。</div>
        <div class="col col-12 font-size-s spacing-xxs">• 从容：我们始终铭记“为您创造便捷生活”的重要性，将为您提供更丰富灵活的租赁解决方案。</div>
        <div class="col col-12 font-size-s spacing-xxs">• 信任：我们为您提供透明的信息服务，并与您建立信任关系。我们不断提升产品和服务质量以履行对您的承诺，使我们成为您值得信赖的伙伴。</div>
        <div class="col col-12 font-size-s spacing-m">• 关怀：我们不断地深入理解您的需求，并努力为您提供更多高附加值的个性化服务。您将在BMW金融/租赁之旅中体会到各种客户关怀服务。</div>
        <div class="col col-12 font-size-s spacing-xl">您是我们一切工作的核心，宝马先锋租赁将为您提供直接透明的租赁方案。除租赁合同中明确约定的费用外，宝马先锋租赁不会就租赁合同的签署和履行向您收取任何费用。</div>
      </div>
      <div class="row spacing-l">
        <div class="col col-12 font-size-l spacing-s">联系我们</div>
        <div class="col col-5">
          <div class="font-size-s spacing-xxxs">先锋租赁</div>
          <div class="font-size-s spacing-xxxs">服务热线及投诉电话：400-995-6618</div>
          <div class="font-size-s spacing-xxxs">电子邮箱：hil_cic@bmw.com</div>
          <div class="font-size-s spacing-s">官方微信：请扫描二维码或微信搜索“宝马租赁”关注</div>
          <div class="font-size-s">我们的客户关怀服务专员待收到您的反馈后会及时跟进您的需求。</div>
        </div>
        <div class="col col-4">
          <div class="wechat-img">
            <img src="../assets/wechat-img.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ''
export default {
  data() {
    return {}
  },
  created() {

  },
  mounted() {

  },
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style lang="less" scoped>
//@import ''
.wechat-img{
  width: 190px;
  height: 190px;
}
</style>
