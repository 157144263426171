<template>
  <div class="view-container">
    <div class="content clear">
      <div class="teaser-container spacing-l shadow-t">
        <img src="../assets/home-teaser.png" alt="">
        <div class="text-content-t-l">
          <div class="font-size-xl spacing-xs color-w">从容、信任、关怀、您身边的挚友</div>
          <div class="font-size-m color-w">BMW租赁为您提供全面优质的租赁服务，</div>
          <div class="font-size-m spacing-s color-w">祝您轻松拥有属于自己的BMW。</div>
          <div class="font-size-s color-w">BMW租赁客户服务及投诉热线：400 995 6618</div>
        </div>
      </div>
      <div class="row">
        <div class="col col-12 font-size-l spacing-xs">全面优质的BMW租赁服务，助您轻松拥有属于自己的BMW。</div>
        <div class="col col-12 font-size-s spacing-xs">BMW租赁服务为您提供了丰富多样的产品。您可以根据自己的需求选择相应的融资/租赁产品，在这期间我们将以从容、信任、关怀为基本理念为您竭诚提供优质服务，助您畅享BMW之悦。</div>
        <div class="col col-12 font-size-xs spacing-l">宝马汽车先锋租赁将为您提供直接透明的租赁方案。除融资/租赁合同中明确约定的费用外，宝马汽车先锋租赁不会就融资/租赁合同的签署和履行向您收取任何费用。</div>
      </div>
      <div class="row">
        <div class="col col-12 shadow-l">
          <img src="../assets/home-list-1.png" alt="">
          <div class="text-content-b-l">
            <div class="font-size-m spacing-xs color-w">丰富多样的产品</div>
            <div class="font-size-s color-w">BMW租赁为您提供了丰富多样的产品。</div>
            <div class="font-size-s spacing-xs color-w">您可以根据自己的需求选择相应的融资/租赁产品。</div>
            <div class="font-size-xs color-w go-product" @click="goProduct">
              <img src="../assets/icon_next.png" alt="">
              了解更多
            </div>
          </div>
        </div>
      </div>
      <div class="row spacing-l">
        <div class="col col-6 shadow-l">
          <img src="../assets/home-list-2.png" alt="">
          <div class="text-content-b-l">
            <div class="font-size-m spacing-xs color-w">宝马短租服务</div>
            <div class="font-size-s spacing-xs color-w">为尊贵的宝马集团客户提供方便快捷的租车服务。</div>
            <div class="font-size-xs color-w go-service" @click="goService">
              <img src="../assets/icon_next.png" alt="">
              了解更多
            </div>
          </div>
        </div>
        <div class="col col-6 shadow-l">
          <img src="../assets/home-list-3.png" alt="">
          <div class="text-content-b-l">
            <div class="font-size-m spacing-xs color-w">关于宝马汽车先锋租赁</div>
            <div class="font-size-s color-w">BMW租赁向客户提供全面优质的服务，为您轻松驾享</div>
            <div class="font-size-s spacing-xs color-w">BMW提供助力。</div>
            <div class="font-size-xs color-w go-about" @click="goAbout">
              <img src="../assets/icon_next.png" alt="">
              了解更多
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  //import ''
  export default {
    data(){
      return {
        
      }
    },
    created(){
      
    },
    mounted(){
    
    },
    computed: {
      
    },
    methods: {
      goProduct:function () {
        this.$router.push({
          path:'/product'
        })
      },
      goService:function () {
        this.$router.push({
          path:'/service'
        })
      },
      goAbout:function () {
        this.$router.push({
          path:'/about'
        })
      }
    },
    watch:{
    
    }
  }
</script>

<style lang="less" scoped>
.go-product,.go-service,.go-about{
  cursor: pointer;
  img{
    display: inline-block;
    width: 20px;
    height: 16px;
    vertical-align: text-top;
  }
}
</style>
